import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

import Layout from "../components/layout";
import LogoWall from "../components/logowall";

export default function Home() {
  return (
    <Layout
      title="JLTWeb.info"
      seoTitle="Conseil et développement Web & applications Mobile sur Rennes"
    >
      <section className="bio container">
        <h2>
          Prestations de services en conseil, accompagnement et développement
          Web &amp; Mobile.
        </h2>

        <p className="note">
          Poste actuel : Responsable d'équipe de développement mobile - Chef de
          projet digital chez{" "}
          <OutboundLink
            href="https://www.niji.fr/"
            target="_blank"
            rel="noreferrer"
          >
            Niji
          </OutboundLink>{" "}
          - Rennes
        </p>

        <p>
          Anciennement consultant et responsable d’équipe technique chez
          Capgemini, j’ai basculé dans le monde du Web en 2012 en rejoignant
          l’agence rennaise MBA Multimedia. Initialement affecté à un projet
          collaboratif de R&D, j’ai ensuite alterné différents postes et travaux
          au sein de l’agence. Je suis depuis en poste au sein de la Software
          Factory Niji (Rennes) en tant que Chef de projets et manager d'équipe
          de développement mobile&nbsp;:
        </p>
        <ul>
          <li>Gestion de projets (budget, risques, planning)</li>
          <li>
            Aide à l’avant-vente, conception technique &amp; fonctionnelle,
            cadrage
          </li>
          <li>Développement front et back-end Web, hybride, mobile</li>
          <li>Optimisation des outils et méthodes de delivery</li>
          <li>Recrutement des profils techniques</li>
          <li>Planification des ressources</li>
        </ul>
        <p>
          Fort de cette expérience technique et humaine, je souhaite continuer à
          évoluer dans ce monde du digital en accompagnant des clients désireux
          de développer leurs outils Web, mobiles, et dispositifs digitaux.
        </p>
      </section>
      <section className="technos container">
        <h2>Technologies, outils et méthodes</h2>
        <p>
          Les principaux outils et technos utilisées sur mes projets pros ou
          persos&nbsp;:
        </p>
        <ul>
          <li>
            Web : PHP (Symfony, Wordpress), Java/J2EE, Javascript (Gatsby,
            jQuery, Angular, React), HTML, CSS (SCSS, SASS)
          </li>
          <li>Mobile : ReactNative, Flutter, Ionic</li>
          <li>
            Gestion de projet, Organisation, UI : JIRA, Confluence, Redmine, Trello,
            Mantis, Zendesk, Office 365, SCRUM, Figma
          </li>
          <li>Autres : Google Analytics, Firebase, Bitrise, Netlify</li>
        </ul>
      </section>
      <LogoWall />
      <section className="clients container">
        <h2>Clients principaux et projets</h2>
        <p>
          Une liste non exhaustive des clients avec qui et pour qui j'ai été
          amené à collaborer durant les dernières années&nbsp;:
        </p>
        <ul>
          <li>Telecoms : Orange, Orange Business, Orange Caraïbes, SFR</li>
          <li>
            Banque, Assurance, Finance : Crédit Agricole, Pacifica, LCL, Groupama Asset Management, Aon, April
          </li>
          <li>
            Divers local : Groupe Beaumanoir, Tout Rennes Court, CH2P Lamballe,
            EMO France, Euromark, Le Groupe Ouest, Claire Lefort immobilier,
            ENSC Rennes, FRAC Bretagne, EMO France
          </li>
          <li>
            Autres : Groupement des Mousquetaires, Acadomia, Vinci Immobilier, L'Oréal, Yves Saint-Laurent
          </li>
        </ul>
      </section>
    </Layout>
  );
}
